<template>
  <v-container
    fluid
    id="regular-table"
  >
    <v-form
      ref="form"
      v-model="is_valid"
    >
      <h4 class="text-h4 font-weight-medium my-4">
        {{ $t("transfer_details") }}
      </h4>
      <div
        class="d-flex flex-column flex-md-row"
        style="gap: 150px"
      >
        <div
          class="flex-grow-1 my-4"
          style="max-width: 700px"
        >
          <v-autocomplete
            outlined
            dense
            :label="$t('from_hub')"
            :items="
              hubs.filter((item) => {
                return item.id != transfer.to_hub_id;
              })
            "
            v-model="transfer.from_hub_id"
            item-value="id"
            item-text="name"
            :loading="isLoadingHubs"
            :rules="[rules.required]"
            @change="handleFromHubChange"
            :search-input.sync="cleanList"
          />
        </div>
        <div
          div
          class="flex-grow-1 my-4"
          style="max-width: 700px"
        >
          <v-autocomplete
            outlined
            dense
            :label="$t('to_hub')"
            :items="
              hubs.filter((item) => {
                return item.id != transfer.from_hub_id;
              })
            "
            item-value="id"
            item-text="name"
            v-model="transfer.to_hub_id"
            :rules="[rules.required]"
            @change="handleToHubChange"
          />
        </div>
      </div>
    </v-form>
    <v-card class="mt-n1">
      <v-toolbar
        dark
        tabs
        flat
        color="#5E2FBE"
        class="hidden-xs-only"
      >
        <template>
          <v-tabs v-model="tabs">
            <v-tab href="#product">
              <v-icon>mdi-format-list-bulleted</v-icon>&nbsp;{{
                $t("products_list")
              }}
            </v-tab>
            <v-tab href="#cart">
              <v-badge
                v-if="transfer.products?.length"
                color="green"
                :content="transfer.products?.length"
              >
                <v-icon>mdi-cart</v-icon>&nbsp;{{ $t("items_in_cart") }}
              </v-badge>
              <span v-else> <v-icon>mdi-cart</v-icon>&nbsp;{{ $t("items_in_cart") }} </span>
            </v-tab>
            <v-tabs-slider color="white"></v-tabs-slider>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>
    <v-card-text class="px-0">
      <v-tabs-items v-model="tabs">
        <v-tab-item value="product">
          <v-row class="mt-n1">
            <v-col
              sm="2"
              md="2"
            >
              <v-text-field
                outlined
                dense
                :label="$t('search')"
                v-model="options.search"
                class=""
              ></v-text-field>
            </v-col>
            <v-col
              sm="2"
              md="2"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.shelf_id"
                :items="shelves"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.shelf_id, 2)"
                :label="$t('shelf')"
              ></v-autocomplete>
            </v-col>
            <v-col
              sm="2"
              md="2"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_parent_id"
                :items="categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.category_parent_id, 3)"
                :label="$t('category')"
              ></v-autocomplete>
            </v-col>
            <v-col
              sm="2"
              md="2"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_id"
                :items="sub_categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingSubCategories"
                :label="$t('sub_category')"
              ></v-autocomplete>
            </v-col>
            <v-col
              sm="2"
              md="2"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.provider_id"
                :items="listProviders"
                item-text="name"
                item-value="id"
                :loading="isLoadingProviders"
                :label="$t('provider')"
              ></v-autocomplete>
            </v-col> </v-row
          ><v-row>
            <v-col
              sm="2"
              md="2"
            >
              <v-text-field
                ref="qteMinInput"
                outlined
                dense
                label="Qte min"
                v-model="options.qte_in_hub_min"
                type="number"
                :rules="[rules.min_quantity(0), minMaxRule]"
              ></v-text-field>
            </v-col>
            <v-col
              sm="2"
              md="2"
              class="pl-0"
            >
              <v-text-field
                ref="qteMaxInput"
                outlined
                dense
                label="Qte max"
                v-model="options.qte_in_hub_max"
                type="number"
                :rules="[rules.quantity, minMaxRule]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="listProducts"
            :loading="isLoadingProducts"
            item-key="id"
            class="elevation-4"
            :server-items-length="productsMeta.totalItems"
            :options.sync="options"
            :items-per-page="10"
            show-expand
            single-expand
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.purchase_price="{ item }">
              {{ Number(item.purchase_price).toFixed(2) }}
            </template>
            <template v-slot:item.qte_in_hub="{ item }">
              <span class="font-weight-medium">{{
                item.qte_in_hub ?? "-"
              }}</span>
            </template>
            <template v-slot:item.stock_destination="{ item }">
              <span class="font-weight-medium">{{
                getStockInDestination(item)
              }}</span>
            </template>
            <template v-slot:item.moving_quantity="{ item }">
              <a @click="openMovingQuantitiesModal(item)">
                <span
                  v-if="item.moving_quantity > 0"
                  class="green--text font-weight-medium"
                  >+ {{ item.moving_quantity }}
                </span>
                <span
                  v-if="item.moving_quantity < 0"
                  class="red--text font-weight-medium"
                  >{{ item.moving_quantity }}</span
                >
              </a>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="isProductInCart(item)"
                small
                color="success"
                @click="openAddTransferItemModal(item)"
                >EDIT</v-btn
              >
              <v-btn
                v-else
                small
                color="#6100ea"
                @click="openAddTransferItemModal(item)"
                >ADD</v-btn
              >
            </template>
            <!-- expand column -->
            <template
              v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            >
              <v-icon
                @click="expand(true)"
                v-if="item.lots?.length > 0 && !isExpanded"
                >mdi-chevron-down</v-icon
              >

              <v-icon
                @click="expand(false)"
                v-if="item.lots?.length > 0 && isExpanded"
                >mdi-chevron-up</v-icon
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table
                  class="my-5"
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("lot_number") }}
                        </th>

                        <th class="text-left">
                          {{ $t("expiration_date") }}
                        </th>

                        <th class="text-left">
                          {{ $t("quantity") }}
                        </th>
                        
                        <th class="text-left">
                          {{ $t("lot_moving_stock_by_batch") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="lot in item.lots"
                        :key="lot.id"
                      >
                        <td>{{ lot.lot_number }}</td>
                        <td>{{ lot.expiration_date }}</td>

                        <td>{{ lot.qte_in_lot }}</td>
                        <td 
                         v-if="lot.lot_moving_stock < 0"
                         class="red--text font-weight-medium"
                        >{{ lot.lot_moving_stock }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="cart">
          <v-data-table
            :headers="cart_headers"
            item-key="name"
            :items="transfer.products"
            class="elevation-0"
            show-expand
            single-expand
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.total_price_ht="{ item }">
              {{
                Number(item.purchase_price_ht * item.qte_in_transfer)?.toFixed(
                  2
                )
              }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                color="#C6C6C6"
                @click="openTransferItemModal(item)"
              >
                $vuetify.icons.iconEditItem
              </v-icon>
              <v-icon @click="deleteItem(item)">
                $vuetify.icons.iconDeleteItem
              </v-icon>
            </template>
            <!-- expand column -->
            <template
              v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            >
              <v-icon
                @click="expand(true)"
                v-if="item.by_lots && !isExpanded"
                >mdi-chevron-down</v-icon
              >

              <v-icon
                @click="expand(false)"
                v-if="item.by_lots && isExpanded"
                >mdi-chevron-up</v-icon
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table
                  class="my-5"
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("lot_number") }}
                        </th>

                        <th class="text-left">
                          {{ $t("expiration_date") }}
                        </th>

                        <th class="text-left">
                          {{ $t("quantity") }}
                        </th>
                        <th class="text-left">
                          {{ $t("qte_to_transfer") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="lot in item.lots_in_transfer"
                        :key="lot.id"
                      >
                        <td>{{ lot.lot_number }}</td>
                        <td>{{ lot.expiration_date }}</td>
                        <td>{{ lot.qte_in_lot }}</td>
                        <td>{{ lot.qte_to_transfer }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
          <div class="mt-8 d-flex">
            <v-btn
              outlined
              small
              class="ml-auto primary--text"
              @click="cancel()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              small
              class="ml-2 primary"
              :disabled="!transfer.products || transfer.products <= 0"
              @click="submitTransfer()"
              >{{ $t("submit_transfer") }}</v-btn
            >
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <product-modal
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      :transferItem="currentTransferItem"
      :toggleModal="() => toggleModal()"
    ></product-modal>
    <submit-transfer-modal
      :isSubmitTransferModalOpen="isSubmitTransferModalOpen"
      :toggleSubmitTransfer="toggleSubmitTransfer"
      :currentTransfer="transfer"
    ></submit-transfer-modal>
    <moving-quantities-modal
      v-if="isMovingQuantitiesModalOpen"
      :isMovingQuantitiesModalOpen="isMovingQuantitiesModalOpen"
      :toggleMovingModal="() => toggleMovingModal()"
      :transferItem="currentTransferItem"
      :listMoving="listMoving"
    >
    </moving-quantities-modal>
  </v-container>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import transferService from "@/store/services/transfer-product-service";
import categoryService from "@/store/services/category-service";
import productService from "@/store/services/product-service";
import debounce from "lodash/debounce";
export default {
  mixins: [validationRules],
  components: {
    ProductModal: () => import("./modals/ProductModal.vue"),
    SubmitTransferModal: () => import("./modals/SubmitTransferModal.vue"),
    MovingQuantitiesModal: () => import("./modals/MovingQuantitiesModal"),
  },
  computed: {
    console: () => console,
    ...mapGetters({
      shelves: "categories/listShelves",
      isLoadingShelves: "categories/isLoadingCategories",
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingSubCategories: "categories/isLoadingCategories",
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/activeHubs",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      isLoadingProviders: "providers/isLoadingProviders",
      listProviders: "providers/listProviders",
    }),
    transfer: {
      get() {
        return this.$store.state.transferProducts.transfer;
      },
      set(value) {
        this.$store.commit("transferProducts/EDITED_TRANSFER", value);
      },
    },
  },
  data() {
    return {
      is_valid: false,
      tabs: true,
      isMovingQuantitiesModalOpen: null,
      currentFromHubID: null,
      categories: [],
      sub_categories: [],
      cleanList: null,
      currentTransferItem: null,
      isModalOpen: false,
      isSubmitTransferModalOpen: false,
      is_loading_product: false,
      search_from_category: null,
      listMoving: null,
      options: { itemsPerPage: 10, page: 1 },
      minMaxRule: (value) => {
        console.log(value);
        const minQuantity = parseInt(this.options.qte_in_hub_min);
        const maxQuantity = parseInt(this.options.qte_in_hub_max);

        if (minQuantity > maxQuantity) {
          return "Minimum quantity cannot be greater than maximum quantity.";
        }

        return true;
      },
      headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "name",
          sortable: false,
          value: "name",
        },
        { text: "Product ID", value: "generated_code" },
        { text: "barcode", value: "barcode" },
        { text: "Purchase price HT", value: "purchase_price_ht" },
        { text: "VAT", value: "purchase_tva" },
        { text: "Moving", value: "moving_quantity" },
        { text: "Stock", value: "qte_in_hub" },

        {
          text: "Stock in Destination",
          value: "stock_destination",
          align: "center",
        },
        { text: "Actions", value: "actions" },
      ],
      cart_headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "name",
          sortable: false,
          value: "name",
        },
        { text: "Product ID", value: "generated_code" },
        { text: "barcode", value: "barcode" },
        { text: "Quantity (Units)", value: "qte_in_transfer" },
        { text: "Purchase price HT", value: "purchase_price_ht" },
        { text: "VAT %", value: "purchase_tva" },
        { text: "Total HT", value: "total_price_ht" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  watch: {
    async cleanList(value) {
      if (value == null) {
        await this.$store.dispatch("products/clean");
      }
    },
    options: {
      handler: debounce(async function () {
        await this.getProduct();
      }, 1000),
      deep: true,
    },
    "options.qte_in_hub_max": {
      handler() {
        this.$refs.qteMinInput.validate();
        this.$refs.qteMaxInput.validate();
      },
      deep: true,
    },
  },
  methods: {
    async openMovingQuantitiesModal(item) {
      const info = await productService.movingQuantities({
        product_id: item.id,
        hub_id: this.transfer.from_hub_id,
      });
      this.listMoving = info;
      this.currentTransferItem = item;
      this.isMovingQuantitiesModalOpen = true;
    },
    async loadCategory(parent_id, level) {
      if (level == 2) {
        const categories = await categoryService.list({
          parent_id,
          level,
        });
        this.categories = categories;
        this.options.category_id = null;
      } else if (level == 3) {
        const sub_categories = await categoryService.list({
          level: 3,
          parent_id,
        });
        this.sub_categories = sub_categories;
      }
    },
    async loadProviders() {
      try {
        await this.$store.dispatch("providers/list", {
          status: "active",
        });
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    async loadShelves() {
      try {
        await this.$store.dispatch("categories/listShelves");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    async getWarehouse() {
      try {
        await this.$store.dispatch("hubs/list", { for: "transfer" });
      } catch (error) {
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    async getProduct() {
      if (this.transfer.from_hub_id) {
        await this.$store.dispatch("products/list", {
          search: this.options.search,
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,

          category_parent_id: this.options.category_parent_id,
          category_id: this.options.category_id,
          provider_id: this.options.provider_id,
          shelf_id: this.transfer.shelf_id,
          hub_id: this.transfer.from_hub_id,
          to_hub_id: this.transfer.to_hub_id,
          with_moving: true,
          with_active_lots: true,
          qte_in_hub_min: this.options.qte_in_hub_min,
          qte_in_hub_max: this.options.qte_in_hub_max,
        });
      }
    },
    async init() {
      await this.loadShelves();
      await this.getWarehouse();
      await this.loadProviders();
    },
    openTransferItemModal(item) {
      this.validate();
      if (this.is_valid) {
        this.currentTransferItem = item;
        if (this.currentTransferItem.discount == null)
          this.currentTransferItem.discount = 0.0;
        this.isModalOpen = true;
      }
    },
    openAddTransferItemModal(item) {
      this.validate();
      if (this.is_valid) {
        this.currentTransferItem =
          this.transfer?.products?.find(
            (transferItem) => transferItem.id == item.id
          ) ?? item;
        if (this.currentTransferItem.discount == null)
          this.currentTransferItem.discount = 0.0;
        this.isModalOpen = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    deleteItem(item) {
      this.$store.dispatch("transferProducts/removeFromCart", {
        ...item,
        transfer_id: this.transfer.id,
      });
    },
    toggleSubmitTransfer() {
      this.isSubmitTransferModalOpen = !this.isSubmitTransferModalOpen;
    },
    submitTransfer() {
      this.toggleSubmitTransfer();
    },
    async handleFromHubChange() {
      try {
        if (this.transfer?.products?.length > 0) {
          const resultSwal = await this.$swal({
            icon: "warning",
            text: this.$t("clear_cart_confirmation_message_transfer"),
            showConfirmButton: true,
            showCancelButton: true,
          });
          if (resultSwal.isConfirmed) {
            this.console.log("confirmed");
            this.updateTransfer(this.transfer);
            this.getProduct();
          } else {
            this.transfer.from_hub_id = this.currentFromHubID;
            this.console.log("refused");
          }
        } else {
          if (this.transfer?.id) {
            this.updateTransfer(this.transfer);
          }
          this.getProduct();
          this.currentFromHubID = this.transfer.from_hub_id;
        }
      } catch (error) {
        this.is_loading = false;
        this.console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    handleToHubChange() {
      console.log(this.transfer.to_hub_id);
      this.getProduct();
      if (this.transfer?.id) {
        this.updateTransfer(this.transfer);
      }
    },
    cancel() {
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");
      this.$router.push("/transfers");
    },
    async updateTransfer(transfer) {
      try {
        // this.validate();
        if (transfer.id) {
          await this.$store.dispatch("transferProducts/update", transfer);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        } else {
          this.$store.dispatch("alerts/error", "Transfer doesn't exist");
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    getStockInDestination(item) {
      if (this.transfer.to_hub_id) {
        const distHub = item.hubs.find(
          (hub) => hub.id == this.transfer.to_hub_id
        );
        if (distHub) return distHub.qte_in_hub;
      }
      return "-";
    },
    toggleMovingModal() {
      this.isMovingQuantitiesModalOpen = !this.isMovingQuantitiesModalOpen;
    },

    isProductInCart(product) {
      return this.transfer.products?.find(
        (transferItem) => transferItem.id == product.id
      )
        ? true
        : false;
    },
  },
  async created() {
    this.$store.commit("transferProducts/TRANSFER", {});
    this.$store.commit("products/CLEAN_LIST");
    await this.init();
    const id = this.$route.params.id;
    if (id) {
      const transferEntity = await transferService.show({
        id,
      });
      this.currentFromHubID = transferEntity.from_hub_id;
      this.$store.commit("transferProducts/TRANSFER", transferEntity);
      this.$store.commit("SET_PAGE_SUBTITLE", transferEntity.tracking_number);
      this.$store.commit("SET_SUBTITLE_ID", "Transfer ID :");
      this.getProduct();
    }
  },
};
</script>